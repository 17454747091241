<template>
  <div v-loading="loadding" class="p0 customEdit">
    <div class="goback">
      <el-button size="small" @click="goback">返 回</el-button>
    </div>
    <div v-if="iframeWidth > 0" class="iframeBox">
      <iframe
        id="iframeRef"
        :style="{ minWidth: iframeWidth + 'px' }"
        class="iframeRef"
        :src="srcStr"
        frameborder="0"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomEdit",
  components: {},
  data() {
    return {
      srcStr: "",
      loadding: true,
      iframeWidth: 0,
    };
  },
  mounted() {
    if (this.$route.query) {
      if (this.$route.query.allWidth) {
        this.iframeWidth = this.$route.query.allWidth;
      }
      if (this.$route.query.id) {
        this.srcStr =
          // "https://hubenergy.cn:18085/jmreport/view/657000504569942016";
          // "https://hubenergy.cn:18085/jmreport/view/657000504569942016" +
          "https://hubenergy.cn:18085/jmreport/view/" +
          this.$route.query.id +
          "?token=" +
          localStorage.getItem("accessToken");
      }
    }
    setTimeout(() => {
      this.loadding = false;
    }, 1500);
  },
  methods: {
    goback(){
      this.$router.push('/data-report/report-custom')
    }
  },
};
</script>

<style>
.app {
  overflow: auto !important;
}
.iframeBox,
.customEdit {
  width: 100%;
  height: 100%;
}
.iframeBox .iframeRef {
  height: 100%;
  width: 100%;
}
.customEdit{
  background: #f4f7fc;
}
.goback{
  display: flex;
  padding-bottom: 10px;
}
</style>
